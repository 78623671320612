import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions/actions';
import HeaderRun from './Header.run'

class Header extends Component {

    constructor() {
        super();

        this.state = {
        }
    }

    componentDidMount() {
        HeaderRun();
    }

     chooseLang = async (e, lang) => {
        e.preventDefault();
        this.props.actions.changeLang(lang);
    }

    toggleUserblock = e => {
        e.preventDefault();
        this.props.actions.toggleSetting('showUserBlock');
    }

    toggleOffsidebar = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('offsidebarOpen');
    }

    toggleCollapsed = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('isCollapsed');
        this.resize()
    }

    toggleAside = e => {
        e.preventDefault()
        this.props.actions.toggleSetting('asideToggled');
    }

    resize() {
        // all IE friendly dispatchEvent
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
        // modern dispatchEvent way
        // window.dispatchEvent(new Event('resize'));
    }

    handleLogOut() {
        localStorage.clear();
        this.props.actions.changeToken("DELETE_TOKEN");
        this.props.actions.doLogout();
    }


    render() {
        return (
            <header className="topnavbar-wrapper">
                { /* START Top Navbar */}
                <nav className="navbar topnavbar">
                    { /* START navbar header */}
                    <div className="navbar-header">
                        <a className="navbar-brand" href="#/">
                            <div className="brand-logo">
                                <img className="img-fluid img-fluid-logo" src="img/logoinside.png" alt="App Logo" />
                            </div>
                            <div className="brand-logo-collapsed">
                                <img className="img-fluid img-fluid-logoinside" src="img/logosplash.png" alt="App Logo" />
                            </div>
                        </a>
                    </div>

                    { /* START Left navbar */}
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            { /* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
                            <a href="" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={this.toggleCollapsed}>
                                <em className="fas fa-bars"></em>
                            </a>
                            { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
                            <a className="nav-link sidebar-toggle d-md-none" onClick={this.toggleAside}>
                                <em className="fas fa-bars"></em>
                            </a>
                        </li>
                    </ul>
                    { /* END Left navbar */}

                    { /* START Right Navbar */}
                    <ul className="navbar-nav flex-row">

                    { /* START Alert menu */ }
                        <UncontrolledDropdown nav inNavbar className="dropdown-list">
                            <DropdownToggle nav className="dropdown-toggle-nocaret">
                                <em className={`sl-flag flag-${this.props.lang}`}></em>
                            </DropdownToggle>
                            { /* START Dropdown menu */ }
                            <DropdownMenu right className="dropdown-menu-right animated flipInX">
                                <DropdownItem>
                                    { /* START list group */ }
                                    <ListGroup>
                                       <ListGroupItem action tag="a" href="" onClick={e => this.chooseLang(e,'sq')}>
                                          <div className="media">
                                             <div className="align-self-start mr-2">
                                                <em className="sl-flag flag-sq"></em>
                                             </div>
                                             <div className="media-body">
                                                <span className="m-0">Shqip</span>
                                             </div>
                                          </div>
                                       </ListGroupItem>
                                       <ListGroupItem action tag="a" href="" onClick={e => this.chooseLang(e,'en')}>
                                          <div className="media">
                                             <div className="align-self-start mr-2">
                                                <em className="sl-flag flag-en"></em>
                                             </div>
                                             <div className="media-body">
                                                <span className="m-0">English</span>
                                             </div>
                                          </div>
                                       </ListGroupItem>
                                       <ListGroupItem action tag="a" href="" onClick={e => this.chooseLang(e,'sr')}>
                                          <div className="media">
                                             <div className="align-self-start mr-2">
                                                <em className="sl-flag flag-sr"></em>
                                             </div>
                                             <div className="media-body">
                                                <span className="m-0">Srpski</span>
                                             </div>
                                          </div>
                                       </ListGroupItem>
                                       <ListGroupItem action tag="a" href="" onClick={e => this.chooseLang(e,'tu')}>
                                          <div className="media">
                                             <div className="align-self-start mr-2">
                                                <em className="sl-flag flag-tu"></em>
                                             </div>
                                             <div className="media-body">
                                                <span className="m-0">Turkish</span>
                                             </div>
                                          </div>
                                       </ListGroupItem>
                                    </ListGroup>
                                    { /* END list group */ }
                                </DropdownItem>
                            </DropdownMenu>
                            { /* END Dropdown menu */ }
                        </UncontrolledDropdown>
                        { /* END Alert menu */ }

                        { /* START lock screen */}
                        <li className="nav-item d-none d-md-block">
                            <a onClick={(e) => { e.preventDefault(); this.handleLogOut() }} title="Log out" aria-haspopup="true" href="#" className="dropdown-toggle-nocaret nav-link" aria-expanded="false">
                                <em className="icon-lock"></em>
                            </a>
                        </li>
                        { /* END lock screen */}
                    </ul>
                    { /* END Right Navbar */}

                    { /* START Search form */}
                    <form className="navbar-form" role="search" action="search.html">
                        <div className="form-group">
                            <input className="form-control" type="text" placeholder="Type and hit enter ..." />
                            <div className="fa fa-times navbar-form-close" data-search-dismiss=""></div>
                        </div>
                        <button className="d-none" type="submit">Submit</button>
                    </form>
                    { /* END Search form */}
                </nav>
                { /* END Top Navbar */}
            </header>
        );
    }
}

Header.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object
};

const mapStateToProps = state => ({ settings: state.settings, loginData: state.loginData, changeToken: state.changeToken, lang:state.lang.lang})
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);
