import { combineReducers } from 'redux';
import settingsReducer from './settings.reducer.js';
import themesReducer from './themes.reducers.js';
import loginData from './login_data.reducer.js';
import tokenReducers from './token.reducers.js';
import langReducer from './language.reducers.js';

export default combineReducers({
    settings: settingsReducer,
    theme: themesReducer,
    token: tokenReducers,
    loginData,
    lang: langReducer
});
