import React, { Suspense, lazy } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { connect } from "react-redux";

/* loader component for Suspense*/
import PageLoader from "./components/Common/PageLoader";
import Base from "./components/Layout/Base";
import BasePage from "./components/Layout/BasePage";
import BaseHorizontal from "./components/Layout/BaseHorizontal";

/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />;

const Customer = lazy(() => import("./components/Customer/Customer"));
const Feedback = lazy(() => import("./components/Feedback/Feedback"));
const EditFeedback = lazy(() => import("./components/Feedback/EditFeedback"));
const Institutions = lazy(() =>
  import("./components/Institutions/Institutions")
);
const Quotes = lazy(() => import("./components/Quotes/Quotes"));
const EditQuotes = lazy(() => import("./components/Quotes/EditQuotes"));
const EditStats = lazy(() => import("./components/Stats/EditStats"));
const EditInstitutions = lazy(() =>
  import("./components/Institutions/EditInstitutions")
);
const Login = lazy(() => import("./components/Pages/Login"));
const Municipalities = lazy(() =>
  import("./components/Municipalities/Municipalities")
);
const EditMunicipalities = lazy(() =>
  import("./components/Municipalities/EditMunicipalities")
);
const Recources = lazy(() => import("./components/Resources/Resources"));
const EditResources = lazy(() =>
  import("./components/Resources/EditResources")
);

const EmergencyCalls = lazy(() =>
  import("./components/EmergencyCalls/EmergencyCalls")
);
const EditEmergenctCall = lazy(() =>
  import("./components/EmergencyCalls/EditEmergencyCall")
);
const InfoContentEn = lazy(() =>
  import("./components/InfoContentViews/InfoContentEn")
);
const InfoContentSQ = lazy(() =>
  import("./components/InfoContentViews/InfoContentSQ")
);
const InfoContentSR = lazy(() =>
  import("./components/InfoContentViews/InfoContentSR")
);
const InfoContentTR = lazy(() =>
  import("./components/InfoContentViews/InfoContentTR")
);
const DefinitionsSQ = lazy(() =>
  import("./components/InfoContentViews/DefinitionsSQ")
);
const DefinitionsEN = lazy(() =>
  import("./components/InfoContentViews/DefinitionsEN")
);
const DefinitionsTR = lazy(() =>
  import("./components/InfoContentViews/DefinitionsTR")
);
const DefinitionsSR = lazy(() =>
  import("./components/InfoContentViews/DefinitionsSR")
);

// List of routes that uses the page layouts
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
  "/login",
  /* See full project for reference */
];

const Routes = ({ location, token }) => {
  const currentKey = location.pathname.split("/")[1] || "/";
  const timeout = { enter: 500, exit: 500 };

  // Animations supported
  //      'rag-fadeIn'
  //      'rag-fadeInRight'
  //      'rag-fadeInLeft'

  const animationName = "rag-fadeIn";
  const isInfoContent = location.pathname.includes("/infoContent-en");
  const isDefinitionsSQ = location.pathname.includes("/definitions-sq");
  const isInfoContentSQ = location.pathname.includes("/infoContent-sq");
  const isInfoContentSR = location.pathname.includes("/infoContent-sr");
  const isInfoContentTR = location.pathname.includes("/infoContent-tr");
  const isDefinitionsEN = location.pathname.includes("/definitions-en");
  const isDefinitionsTR = location.pathname.includes("/definitions-tr");
  const isDefinitionsSR = location.pathname.includes("/definitions-sr");

  if (listofPages.indexOf(location.pathname) > -1) {
    if (token == null || typeof token == "undefined") {
      return (
        // Page Layout component wrapper
        <BasePage>
          <Suspense fallback={<PageLoader />}>
            <Switch location={location}>
              <Route path="/login" component={waitFor(Login)} />
              <Route exact path="/quotes" component={waitFor(Quotes)} />
              {/* See full project for reference */}
            </Switch>
          </Suspense>
        </BasePage>
      );
    } else {
      return (
        // Page Layout component wrapper
        <BasePage>
          <Suspense fallback={<PageLoader />}>
            <Switch location={location}>
              <Route path="/login" component={waitFor(Login)} />
              <Route exact path="/quotes" component={waitFor(Quotes)} />
              {/* See full project for reference */}
            </Switch>
          </Suspense>
        </BasePage>
      );
    }
  } else {
    if (token == null || typeof token == "undefined") {
      if (!isInfoContent && !isDefinitionsSQ && !isInfoContentSQ && !isInfoContentSR && !isInfoContentTR && !isDefinitionsEN && !isDefinitionsSR && !isDefinitionsTR) {
        return (
          <Base>
            <TransitionGroup>
              <CSSTransition
                key={currentKey}
                timeout={timeout}
                classNames={animationName}
                exit={false}
              >
                <div>
                  <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                      <Route path="/login" component={waitFor(Login)} />
                      <Route exact path="/quotes" component={waitFor(Quotes)} />
                    </Switch>
                  </Suspense>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </Base>
        );
      } else {
        return (
          <div>
            <TransitionGroup>
              <CSSTransition
                key={currentKey}
                timeout={timeout}
                classNames={animationName}
                exit={false}
              >
                <div>
                  <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                      <Route path="/login" component={waitFor(Login)} />
                      <Route
                        exact
                        path="/infoContent-sq"
                        component={waitFor(InfoContentSQ)}
                      />
                      <Route
                        exact
                        path="/infoContent-sr"
                        component={waitFor(InfoContentSR)}
                      />
                      <Route
                        exact
                        path="/infoContent-en"
                        component={waitFor(InfoContentEn)}
                      />
                      <Route
                        exact
                        path="/infoContent-tr"
                        component={waitFor(InfoContentTR)}
                      />
                      <Route
                        exact
                        path="/definitions-sq"
                        component={waitFor(DefinitionsSQ)}
                      />
                       <Route
                        exact
                        path="/definitions-en"
                        component={waitFor(DefinitionsEN)}
                      />
                       <Route
                        exact
                        path="/definitions-sr"
                        component={waitFor(DefinitionsSR)}
                      />
                       <Route
                        exact
                        path="/definitions-tr"
                        component={waitFor(DefinitionsTR)}
                      />
                      <Route exact path="/quotes" component={waitFor(Quotes)} />
                    </Switch>
                  </Suspense>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
        );
      }
      // Layout component wrapper
      // Use <BaseHorizontal> to change layout
    } else {
      if (isInfoContent || isDefinitionsSQ || isDefinitionsEN || isDefinitionsTR || isDefinitionsSR || isInfoContentSQ || isInfoContentSR || isInfoContentTR) {
        return (
          <div>
            <TransitionGroup>
              <CSSTransition
                key={currentKey}
                timeout={timeout}
                classNames={animationName}
                exit={false}
              >
                <div>
                  <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                      <Route
                        exact
                        path="/infoContent-sq"
                        component={waitFor(InfoContentSQ)}
                      />
                      <Route
                        exact
                        path="/infoContent-sr"
                        component={waitFor(InfoContentSR)}
                      />
                      <Route
                        exact
                        path="/infoContent-en"
                        component={waitFor(InfoContentEn)}
                      />
                      <Route
                        exact
                        path="/infoContent-tr"
                        component={waitFor(InfoContentTR)}
                      />
                      <Route
                        exact
                        path="/definitions-sq"
                        component={waitFor(DefinitionsSQ)}
                      />
                      <Route
                        exact
                        path="/definitions-en"
                        component={waitFor(DefinitionsEN)}
                      />
                       <Route
                        exact
                        path="/definitions-sr"
                        component={waitFor(DefinitionsSR)}
                      />
                       <Route
                        exact
                        path="/definitions-tr"
                        component={waitFor(DefinitionsTR)}
                      />
                    </Switch>
                  </Suspense>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </div>
        );
      } else {
        return (
          // Layout component wrapper
          // Use <BaseHorizontal> to change layout
          <Base>
            <TransitionGroup>
              <CSSTransition
                key={currentKey}
                timeout={timeout}
                classNames={animationName}
                exit={false}
              >
                <div>
                  <Suspense fallback={<PageLoader />}>
                    <Switch location={location}>
                      <Route
                        exact
                        path="/customer"
                        component={waitFor(Customer)}
                      />
                      <Route
                        exact
                        path="/feedback"
                        component={waitFor(Feedback)}
                      />
                      <Route
                        exact
                        path="/editFeedback/:idFeedback"
                        component={waitFor(EditFeedback)}
                      />
                      <Route
                        exact
                        path="/editFeedback"
                        component={waitFor(EditFeedback)}
                      />
                      <Route
                        exact
                        path="/institutions"
                        component={waitFor(Institutions)}
                      />
                      <Route
                        exact
                        path="/editInstitutions"
                        component={waitFor(EditInstitutions)}
                      />
                      <Route
                        exact
                        path="/editInstitutions/:idInst"
                        component={waitFor(EditInstitutions)}
                      />
                      <Route exact path="/quotes" component={waitFor(Quotes)} />
                      <Route
                        exact
                        path="/editQuotes/:idQuote"
                        component={waitFor(EditQuotes)}
                      />
                      <Route
                        exact
                        path="/editQuotes"
                        component={waitFor(EditQuotes)}
                      />
                      <Route
                        exact
                        path="/editStats"
                        component={waitFor(EditStats)}
                      />
                      <Route
                        exact
                        path="/municipalities"
                        component={waitFor(Municipalities)}
                      />
                      <Route
                        exact
                        path="/editMunicipalities/:idMunicipality"
                        component={waitFor(EditMunicipalities)}
                      />
                      <Route
                        exact
                        path="/editMunicipalities"
                        component={waitFor(EditMunicipalities)}
                      />
                      <Route
                        exact
                        path="/resources"
                        component={waitFor(Recources)}
                      />
                      <Route
                        exact
                        path="/editResources/:idResource"
                        component={waitFor(EditResources)}
                      />
                      <Route
                        exact
                        path="/editResources"
                        component={waitFor(EditResources)}
                      />
                      <Route
                        exact
                        path="/emergencyCalls"
                        component={waitFor(EmergencyCalls)}
                      />
                      <Route
                        exact
                        path="/editEmergencyContact"
                        component={waitFor(EditEmergenctCall)}
                      />
                      <Route
                        exact
                        path="/editEmergencyContact/:idEmergencyContact"
                        component={waitFor(EditEmergenctCall)}
                      />
                      {token &&
                      <Redirect to="/quotes" />
                      }
                    </Switch>
                  </Suspense>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </Base>
        );
      }
    }
  }
};

function mapStateToProps(state) {
  return {
    token: state.loginData.token !== "" ? state.loginData.token : null,
  };
}

export default withRouter(connect(mapStateToProps)(Routes));
