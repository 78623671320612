const Menu = [
  {
    heading: "Main Navigation",
    translate: "sidebar.heading.HEADER",
  },
  {
    name: "Quotes",
    path: "/quotes",
    icon: "fas fa-quote-right",
  },
  {
    name: "Institutions",
    path: "/institutions",
    icon: "fas fa-building",
  },
  {
    name: "Stats",
    path: "/editStats",
    icon: "icon-grid",
  },
  {
    name: "Customer",
    path: "/customer",
    icon: "fas fa-user-tag",
  },
  {
    name: "Support Emails",
    path: "/feedback",
    icon: "fas fa-envelope",
  },
  {
    name: "Municipalities",
    path: "/Municipalities",
    icon: "far fa-building",
  },
  {
    name: "Resources",
    path: "/resources",
    icon: "fas fa-info",
  },
  {
    name: "Emergency Calls",
    path: "/emergencyCalls",
    icon: "fa fa-phone-square",
  },
];

export default Menu;
