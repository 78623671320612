import { LANG } from '../actions/actions';

const initialState = {
    lang: 'en'
}

const langReducer = (state = initialState, action) => {
    switch (action.type) {
        case LANG:
            return {
                ...state,
                lang: action.name
            }
        default:
            return state;
    }
}

export default langReducer;
