import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import config from "./config.js";
// App Routes
import Routes from "./Routes";
import jwt from "jsonwebtoken";
// Vendor dependencies
import "./Vendor";
// Application Styles
import "./styles/bootstrap.scss";
import "./styles/app.scss";
import { connect } from "react-redux";

class App extends Component {
  componentWillMount() {
    if (this.props.token) {
      var decoded = jwt.decode(this.props.token, { complete: true }).payload;
      //If token is expired
      if (Date.now() > decoded.exp * 1000) {
        if (window.location.pathname !== '/login'){
          window.location.replace('/login')
         }
      } 
      else {
        axios.defaults.headers.common = {
          Authorization: `Bearer ${this.props.token}`,
        };
        if (window.location.pathname === "/login") {
          window.location.replace("/quotes");
        }
      }
    }
     else {
      if (window.location.pathname !== "/login" && window.location.pathname !== "/infoContent-en" && window.location.pathname !== '/definitions-sq' && window.location.pathname !== "/infoContent-sq"  && window.location.pathname !== "/infoContent-sr"  && window.location.pathname !== "/infoContent-tr" && window.location.pathname !== "/definitions-en" && window.location.pathname !== "/definitions-tr" && window.location.pathname !== "/definitions-sr") {
        window.location.replace("/login");
      }
    }
  }

  render() {
    console.log('window', window.location.pathname)
    axios.defaults.baseURL = config.BASE_PATH;
    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    /* global PUBLIC_URL */
    const basename =
      process.env.NODE_ENV === "development" ? "/" : PUBLIC_URL || "/";

    return (
      <BrowserRouter basename={basename}>
        <Routes />
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.loginData.token && state.loginData.token,
  };
};
export default connect(mapStateToProps)(App);
