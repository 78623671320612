import axios from "axios";

export const LOGIN_DATA = "LOGIN_DATA";
export const LOGOUT_DATA = "LOGOUT_DATA";

export function loginData(name, value) {
  return { type: name, value };
}

export const doLogin = (email, password) => async (dispatch, getState) => {
  try {
    const response = await axios.post("/auth/login", { email, password });
    if (response.data.success) {
      const { email, token } = response.data;
      axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

      dispatch({
        type: LOGIN_DATA,
        payload: {
          email,
          token,
          error: "",
        },
      });
      window.location.replace("/quotes");
    } else {
      dispatch({
        type: LOGIN_DATA,
        payload: { error: response.data.message },
      });
    }
  } catch (e) {
    if (e.response.data) {
      dispatch({
        type: LOGIN_DATA,
        payload: { error: e.response.data.message },
      });
    } else {
      dispatch({
        type: LOGIN_DATA,
        payload: { error: "Unable to comunicate with the server." },
      });
    }
  }
};

export const doLogout = () => (dispatch) => {
  dispatch({
    type: LOGIN_DATA,
    payload: {
      email: "",
      token: "",
      error: "",
    },
  });
  axios.defaults.headers.common = { Authorization: null };
  window.location.replace("/login");
};
