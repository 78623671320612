import { TOKEN, DELETE_TOKEN } from '../actions/actions';

const initialToken = null;

const tokenReducers = (state = initialToken, action) => {
    switch(action.type){
        case TOKEN:
            var token = action.value
            return token
        case DELETE_TOKEN:
            return null
        default:
            return state;
    }
}

export default tokenReducers;