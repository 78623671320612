import { LOGIN_DATA, LOGOUT_DATA } from "../actions/actions";

const initialData = {
  email: "",
  token: "",
  error: "",
};

const loginData = (state = initialData, action) => {
  switch (action.type) {
    case LOGIN_DATA:
      if (action.payload !== undefined) {
        return action.payload;
      } else {
        return state;
      }
    case LOGOUT_DATA:
      return initialData;
    default:
      return state;
  }
};

export default loginData;
